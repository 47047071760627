<template lang="html">
  <div class="form" v-if="globalSettings">
    <div class="form__heading">
      <Heading
        :text="globalSettings['labels_and_titles']['data_title']"
        :hType="2"
        textAlign="center"
      />
    </div>
    <div class="form__fields">
      <div class="form__row">
        <div class="form__col-small">
          <Select
            v-model="formData.anrede"
            :label="globalSettings['labels_and_titles']['salutation']"
            :options="salutationVariants"
            :default="salutationVariants[0]"
          />
        </div>
        <div class="form__col-big">
          <InputText
            :label="`${globalSettings['labels_and_titles']['first_name']}*`"
            placeholder="Ihr Vorname"
            :isError="!formData.vorname.valid"
            :errorMessage="formData.vorname.error"
            v-model="formData.vorname.value"
            @change="validateField('vorname')"
          />
        </div>
      </div>

      <div class="form__row">
        <div class="form__col">
          <InputText
            :label="`${globalSettings['labels_and_titles']['last_name']}*`"
            placeholder="Ihr Nachname"
            :isError="!formData.nachname.valid"
            :errorMessage="formData.nachname.error"
            v-model="formData.nachname.value"
            @change="validateField('nachname')"
          />
        </div>
      </div>

      <div class="form__row">
        <div class="form__col">
          <InputText
            :label="`${globalSettings['labels_and_titles']['email']}*`"
            :placeholder="globalSettings['labels_and_titles']['your_email']"
            :isError="!formData.email.valid"
            :errorMessage="formData.email.error"
            v-model="formData.email.value"
            @change="validateField('email')"
          />
        </div>
      </div>

      <div class="form__row" v-if="typeForm !== 'candle'">
        <div class="form__col">
          <InputText
            :label="`${globalSettings['labels_and_titles']['phone']}${
              formData.telefonnummer.validation.includes('required') ? '*' : ''
            }`"
            :placeholder="globalSettings['labels_and_titles']['your_phone']"
            :isError="!formData.telefonnummer.valid"
            :errorMessage="formData.telefonnummer.error"
            v-model="formData.telefonnummer.value"
            @change="validateField('telefonnummer')"
          />
        </div>
      </div>

      <div class="form__row">
        <div class="form__col">
          <CountryInput
            :label="`${globalSettings['labels_and_titles']['land']}*`"
            :placeholder="globalSettings['labels_and_titles']['your_land']"
            :isError="!formData.land.valid"
            :startValue="formData.land.value"
            :errorMessage="formData.land.error"
            @selectCountry="selectCountry"
          />
        </div>
      </div>

      <Checkbox
        v-model="donationReceipt"
        inputValue="yes"
        :inputLabel="
          globalSettings['labels_and_titles']['donation_receipt_checkbox']
        "
      />
      <template v-if="donationReceipt.length">
        <div class="form__row">
          <div class="form__col-big">
            <InputText
              :label="`${globalSettings['labels_and_titles']['city']}*`"
              :placeholder="
                globalSettings['labels_and_titles']['city_placeholder']
              "
              :isError="!formData.city.valid"
              :errorMessage="formData.city.error"
              v-model="formData.city.value"
              @change="validateField('city')"
            />
          </div>
          <div class="form__col-small">
            <InputText
              :label="`${globalSettings['labels_and_titles']['plz']}*`"
              :placeholder="globalSettings['labels_and_titles']['your_plz']"
              :isError="!formData.plz.valid"
              :errorMessage="formData.plz.error"
              v-model="formData.plz.value"
              @change="validateField('plz')"
            />
          </div>
        </div>
        <div class="form__row">
          <div class="form__col-big">
            <InputText
              :label="`${globalSettings['labels_and_titles']['street']}`"
              :placeholder="globalSettings['labels_and_titles']['your_street']"
              :isError="!formData.strabe.valid"
              :errorMessage="formData.strabe.error"
              v-model="formData.strabe.value"
              @change="validateField('strabe')"
            />
          </div>
          <div class="form__col-small">
            <InputText
              :label="`${globalSettings['labels_and_titles']['nr']}`"
              :placeholder="globalSettings['labels_and_titles']['your_nr']"
              :isError="!formData.nr.valid"
              :errorMessage="formData.nr.error"
              v-model="formData.nr.value"
              @change="validateField('nr')"
            />
          </div>
        </div>
      </template>

      <Checkbox
        v-if="donationReceipt.length"
        v-model="donationCompanyName"
        inputValue="yes"
        :inputLabel="
          globalSettings['labels_and_titles']['donate_company_checkbox']
        "
      />

      <template v-if="donationCompanyName.length">
        <div class="form__row">
          <div class="form__col">
            <InputText
              :label="`${globalSettings['labels_and_titles']['company_name']}`"
              :placeholder="
                globalSettings['labels_and_titles']['your_company_name']
              "
              :isError="!formData.firmenname.valid"
              :errorMessage="formData.firmenname.error"
              v-model="formData.firmenname.value"
              @change="validateField('firmenname')"
            />
          </div>
        </div>
      </template>

      <template v-if="isStoneForm || isOrganForm">
        <Checkbox
          inputValue="yes"
          inputLabel="Ich möchte eine Nachricht hinterlassen"
          v-model="isShowMessage"
        />
        <Textarea
          v-if="isShowMessage.length"
          v-model="message"
          label="NACHRICHT (max. 200 Zeichen)"
          :placeholder="globalSettings['labels_and_titles']['your_message']"
          :maxlength="200"
        />
      </template>
    </div>
    <div class="form__buttons">
      <div class="form__button">
        <Button @click="submitForm">
          <span>{{ globalSettings['button_names']['next_step'] }}</span>
        </Button>
      </div>
      <div class="form__button">
        <Button styleButton="border" @click="cancelForm">
          <span>{{ globalSettings['button_names']['cancel'] }}</span>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/InputText/InputText.vue';
import CountryInput from '@/components/CountryInput/CountryInput.vue';
import Select from '@/components/Select/Select.vue';
import Checkbox from '@/components/Checkbox/Checkbox.vue';
import Button from '@/components/Button/Button.vue';
import Heading from '@/components/Heading/Heading.vue';
import Textarea from '@/components/Textarea/Textarea.vue';

export default {
  name: 'FormData',
  components: {
    Textarea,
    InputText,
    Select,
    Checkbox,
    Button,
    Heading,
    CountryInput,
  },
  props: {
    typeForm: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isShowMessage: [],
      salutationVariants: [
        { value: 'Mr.', label: 'Herr' },
        { value: 'Mrs.', label: 'Frau' },
      ],
      donationReceipt: [],
      donationCompanyName: [],
      message: '',
      formData: {
        vorname: {
          value: '',
          valid: true,
          validation: ['required'],
          hidden: false,
          error: '',
        },
        anrede: {
          value: {},
          valid: true,
          // validation: ['required'],
          hidden: false,
          error: '',
        },
        nachname: {
          value: '',
          valid: true,
          validation: ['required'],
          hidden: false,
          error: '',
        },
        telefonnummer: {
          value: '',
          valid: true,
          validation: ['required', 'phone'],
          hidden: false,
          error: '',
        },
        land: {
          value: '',
          valid: true,
          validation: ['required'],
          hidden: false,
          error: '',
        },
        email: {
          value: '',
          valid: true,
          validation: ['required', 'email'],
          hidden: false,
          error: '',
        },
        strabe: {
          value: '',
          valid: true,
          validation: ['required'],
          hidden: true,
          group: 'receipt',
          error: '',
        },
        city: {
          value: '',
          valid: true,
          validation: ['required'],
          hidden: true,
          group: 'receipt',
          error: '',
        },
        nr: {
          value: '',
          valid: true,
          validation: ['required'],
          hidden: true,
          group: 'receipt',
          error: '',
        },
        plz: {
          value: '',
          valid: true,
          validation: ['required'],
          hidden: true,
          group: 'receipt',
          error: '',
        },
        firmenname: {
          value: '',
          valid: true,
          validation: ['required'],
          hidden: true,
          group: 'company',
          error: '',
        },
      },
    };
  },
  watch: {
    donationReceipt(val) {
      this.eventAfterHideFields(val, 'receipt');
    },
    donationCompanyName(val) {
      this.eventAfterHideFields(val, 'company');
    },
  },
  computed: {
    globalSettings() {
      return this.$store.state.global.settings;
    },
    isStoneForm() {
      return this.typeForm === 'stone';
    },
    isOrganForm() {
      return this.typeForm === 'organ';
    },
  },
  mounted() {
    const submitFormData = JSON.parse(localStorage.getItem('submitFormData'));
    if (submitFormData) {
      for (const submitFormDataKey in submitFormData) {
        if (this.formData[submitFormDataKey]) {
          this.formData[submitFormDataKey] = submitFormData[submitFormDataKey];
        }
      }
    }

    this.donationReceipt =
      localStorage.getItem('donationReceipt') === 'yes' ? ['yes'] : [];
    this.donationCompanyName =
      localStorage.getItem('donationCompanyName') === 'yes' ? ['yes'] : [];

    if (this.isStoneForm || this.isOrganForm) {
      this.formData.telefonnummer.validation = ['phone'];
    }
  },
  methods: {
    validateField(field) {
      const fieldValidation = this.formData[field].validation;
      if (Array.isArray(fieldValidation)) {
        if (fieldValidation.includes('required')) {
          this.formData[field].valid = this.formData[field].value !== '';
          if (!this.formData[field].valid) {
            this.formData[field].error =
              this.globalSettings['labels_and_titles']['form_errors'][
                'required'
              ];
            return;
          }
        }
        if (fieldValidation.includes('phone') && this.formData[field].value) {
          const regex =
            /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im;
          this.formData[field].valid = regex.test(this.formData[field].value);
          if (!this.formData[field].valid) {
            this.formData[field].error =
              this.globalSettings['labels_and_titles']['form_errors']['phone'];
            return;
          }
        }
        if (fieldValidation.includes('email')) {
          const regex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          this.formData[field].valid = regex.test(this.formData[field].value);
          if (!this.formData[field].valid) {
            this.formData[field].error =
              this.globalSettings['labels_and_titles']['form_errors']['email'];
            return;
          }
        }

        if (this.formData[field].valid) {
          this.formData[field].error = '';
        }
      }
    },
    submitForm() {
      if (this.typeForm === 'candle') delete this.formData.telefonnummer;

      const validValuesArr = [];
      for (const field in this.formData) {
        if (!this.formData[field].hidden && this.formData[field].validation) {
          this.validateField(field);
          validValuesArr.push(this.formData[field].valid);
        }
      }

      if (validValuesArr.every((el) => el === true)) {
        this.$emit('submitForm', {
          formData: this.formData,
        });
        localStorage.setItem('submitFormData', JSON.stringify(this.formData));

        // if (this.typeForm === 'candle') delete this.formData.telefonnummer;

        this.$store.commit('donationPayment/setFormData', this.formData);

        localStorage.setItem(
          'donationReceipt',
          this.donationReceipt.length ? 'yes' : 'no'
        );
        localStorage.setItem(
          'donationCompanyName',
          this.donationCompanyName.length ? 'yes' : 'no'
        );
      }

      if (this.isStoneForm) {
        const previewData = {
          message: this.message,
        };
        this.$store.commit('stones/setPreviewData', previewData);
      }

      if (this.isOrganForm) {
        const previewData = {
          message: this.message,
        };
        this.$store.commit('organ/setPreviewData', previewData);
      }
    },
    cancelForm() {
      this.$emit('cancelSubmit');
    },
    eventAfterHideFields(stateCheckbox, group) {
      if (stateCheckbox.length) {
        for (const valKey in this.formData) {
          if (this.formData[valKey]['group'] === group) {
            this.formData[valKey].hidden = false;
          }
        }
      } else {
        for (const valKey in this.formData) {
          if (this.formData[valKey]['group'] === group) {
            this.formData[valKey].hidden = true;
            this.formData[valKey].valid = true;
          }
        }
      }
    },
    selectCountry(e) {
      this.formData.land.value = e;
      this.validateField('land');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
